import axios from 'axios'
import Vue from 'vue'
import 'vuetify/dist/vuetify.min.css'
import App from './App.vue'
import './assets/styles/global.css'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

axios.defaults.validateStatus = function (status) {
  return true
}

async function Init () {
  document.addEventListener('contextmenu', event => {
    event.preventDefault()
  })
  const configResponse = await axios.get('config.json')
  if (configResponse.status === 200) {
    window.AppConfig = configResponse.data
  }

  const avatarResponse = await axios.get(`${window.AppConfig.api_url}/auth/default/avatar`)
  if (avatarResponse.status === 200 && avatarResponse.data.success) {
    window.AvatarList = avatarResponse.data.data
  }

  let isRefreshed = false

  // 检测是否是刷新操作
  window.addEventListener('beforeunload', () => {
    isRefreshed = true
  })

  // 在关闭页面时执行登出逻辑
  window.addEventListener('unload', () => {
    if (!isRefreshed) {
      const token = sessionStorage.getItem('token')
      if (!token || token === '') return
      if (!window.AppConfig) return

      const url = `${window.AppConfig.api_url}/auth/logout`
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
      const blob = new Blob([JSON.stringify({})], { type: 'application/json' })
      navigator.sendBeacon(url, blob)
    }
  })

  window.setInterval(async () => {
    const token = sessionStorage.getItem('token')
    if (!token || token === '') return
    if (!window.AppConfig) return
    const response = await axios.get(
      `${window.AppConfig.api_url}/auth/refresh_token`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    if (response.status !== 200 || !response.data.success) {
      window.alert('登入狀態已經遺失, 需要重新登入.')
      window.location.href = `${document.location.protocol}//${document.location.hostname}:${document.location.port}${document.location.pathname}#/login`
    }
  }, 1000 * 60 * 60)

  Vue.config.productionTip = false
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

Init()

window.WebSocketObject = null

window.WebSocketConnect = function WebSocketConnect () {
  const id = sessionStorage.getItem('u_id')
  if (id === null || id === '') return

  window.WebSocketObject = new WebSocket(`${window.AppConfig.ws_url}${id}`)

  window.WebSocketObject.onclose = function (e) {
    console.log('WebSocket closed')
    const uid = sessionStorage.getItem('u_id')

    if (uid !== null && uid !== '') WebSocketConnect(uid)
  }

  window.WebSocketObject.onerror = function (e) {
    window.WebSocketObject.close()
  }
}

window.DateDiffYears = function (date1, date2) {
  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= (60 * 60 * 24)
  return Math.abs(Math.ceil(diff / 365.25))
}
