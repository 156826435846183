<script>
export default {
  name: 'app'
}
</script>

<template>
  <v-app>
    <slot name="app_bar"></slot>
    <v-main class="main">
      <slot name="main"></slot>
    </v-main>
    <slot name="footer"></slot>
  </v-app>
</template>
<script>
export default {
  name: 'app',
  mounted() {
    // 动态加载 Google Analytics 脚本
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-DKJG0YWEFJ';
    document.head.appendChild(gtagScript);

    gtagScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-DKJG0YWEFJ');
    };
  },
};
</script>

<style>
.main {
  margin: 0;
  color: #31383f;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: #f2e9eb;
  width: 100%;
  height: 100%;
}
</style>
