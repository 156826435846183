import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home-view.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/privacy-view.vue')
  },
  {
    path: '/service',
    name: 'service',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/service-view.vue')
  },
  {
    path: '/register-man',
    name: 'register-man',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register-man-view.vue')
  },
  {
    path: '/register-woman',
    name: 'register-woman',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register-woman-view.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login-view.vue')
  },
  {
    path: '/home-man',
    name: 'home-man',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home-man-view.vue')
  },
  {
    path: '/home-woman',
    name: 'home-woman',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home-woman-view.vue')
  },
  {
    path: '/woman-info/:id',
    name: 'woman-info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/woman-info-view.vue'),
    props: true
  },
  {
    path: '/man-list',
    name: 'man-list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/man-list.vue'),
    props: true
  },
  {
    path: '/chat-list',
    name: 'chat-list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/chat-list.vue'),
    props: true
  },
  {
    path: '/detail/:id', // 路徑包含動態參數 :id
    name: 'man-detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/man-detail.vue')
  },
  {
    path: '/chat-detail/:id', // 路徑包含動態參數 :id
    name: 'chat-detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/chat-detail.vue')
  },
  {
    path: '/man-info',
    name: 'man-info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/man-info-view.vue')
  },
  {
    path: '/purchase/deposit',
    name: '/purchase-deposit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/man-deposit-view.vue')
  },
  {
    path: '/purchase/depositlogin',
    name: '/purchase-depositlogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/man-depositLogin-view.vue')
  },
  {
    path: '/deposit-record',
    name: 'deposit-record',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/deposit-record-view.vue')
  },
  {
    path: '/transactions-chat-record',
    name: 'transactions-chat-record',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/transactions-chat-record-view.vue')
  },
  {
    path: '/transactions-record/:type',
    name: 'transactions-record',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/transactions-record-view.vue'),
    props: true
  },
  {
    path: '/man-chat-list',
    name: 'man-chat-list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/man-chat-list-view.vue')
  },
  {
    path: '/man-chat-room/:id',
    name: 'man-chat-room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/man-chat-room-view.vue'),
    props: true
  },
  {
    path: '/woman-setting',
    name: 'woman-setting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/woman-setting.vue'),
    props: true
  },
  {
    path: '/current-points',
    name: 'current-points',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/current-points.vue'),
    props: true
  },
  {
    path: '/income-details',
    name: 'income-details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/income-details.vue'),
    props: true
  },
  {
    path: '/redemption-records',
    name: 'redemption-records',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/redemption-records.vue'),
    props: true
  },
  {
    path: '/account-verification',
    name: 'account-verification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/account-verification.vue'),
    props: true
  },
  {
    path: '/redeem-points',
    name: 'redeem-points',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/redeem-points.vue'),
    props: true
  },
  {
    path: '/identity-verification',
    name: 'identity-verification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/identity-verification.vue'),
    props: true
  },
  {
    path: '/woman-edit',
    name: 'woman-edit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/woman-edit.vue'),
    props: true
  },
  {
    path: '/recharge',
    name: 'recharge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/recharge.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
})

export default router
